<template>
    <div class="app">
        <aside class="sidebar" :class="{'md-hidden': sidebar_visibility.md_hidden, 'sm-hidden': sidebar_visibility.sm_hidden}">
            <div class="logo">
                <router-link :to="{ name: 'Home' }">
                    <img src="~@/assets/images/logo/1991spwg.png"/>
                </router-link>
            </div>
            <nav class="ui secondary vertical pointing fluid inverted menu">
                <template v-if="isAdmin">
                    <router-link class="item" :to="{ name: 'Users' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">User Management</router-link>
                    <router-link class="item" :to="{ name: 'Devices' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Device Control</router-link>
                    <router-link class="item" :to="{ name: 'Outlets' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Outlets</router-link>
                    <router-link class="item" :to="{ name: 'Treatments' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Treatment Setup</router-link>
                </template>
                <router-link class="item" :to="{ name: 'Customers' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Customers</router-link>
                <router-link class="item" :to="{ name: 'OutstandingRecords' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Outstanding Records <label class="ui tiny red circular label" v-if="outstanding_count">{{(outstanding_count === -1)? "!" : outstanding_count}}</label></router-link>
                <router-link class="item" :to="{ name: 'Referred' }" exact-active-class="router-link-exact-active active" @click.native="hideMenu">Referred Registrations</router-link>
            </nav>
        </aside>
        <main>
            <header>
                <nav>
                    <button type="button" class="menu-toggler" @click="toggleMenu"><i class="bars icon"></i></button>
                    <div class="ui text menu">
                        <router-link class="item" :to="{ name: 'Home' }">Dashboard</router-link>
                    </div>
                    <div class="right menu">
                        <div>
                            <div>
                                <div ref="profilepopup">
                                    <avatar initial-class="circular purple" :user="_user"/>
                                </div>
                                <div class="ui basic profile wide popup bottom right transition hidden segments">
                                    <div class="ui secondary segment">
                                        <div class="ui unstackable items">
                                            <div class="profile item">
                                                <div class="ui small image">
                                                    <avatar initial-class="circular basic" :user="_user"/>
                                                </div>
                                                <div class="middle aligned content">
                                                    <a class="header">{{_user|displayname}}</a>
                                                    <div class="meta">
                                                        <label class="ui id basic purple label"><strong>ID:</strong> {{(`${_user.id}`).padStart(7, "0")}}</label>
                                                    </div>
                                                    <div class="ui hidden medium divider"></div>
                                                    <div class="extra">
                                                        <div class="email"><i class="envelope icon"></i> {{_user.username}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ui olive segment">
                                        <div class="ui very relaxed link list">
                                            <div class="item">
                                                <router-link class="ui link" :to="{ name: 'Profile' }" @click.native="hideProfilePopup">
                                                    Edit Profile
                                                </router-link>
                                            </div>
                                            <div class="item">
                                                <router-link class="ui link" :to="{ name: 'ChangePassword' }" @click.native="hideProfilePopup">
                                                    Change Password
                                                </router-link>
                                            </div>
                                            <div class="item">
                                                <a class="ui link" href="javascript:void(0);" @click="logOut">Log Out</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <article class="content" :class="keepAliveViews">
                <keep-alive :include="keepAliveViews">
                    <router-view :key="`view_${$route.fullPath}`"/>
                </keep-alive>
            </article>
            <div class="backdrop" v-if="!sidebar_visibility.sm_hidden" @click="toggleMenu"></div>
        </main>
    </div>
</template>

<script>
import Avatar from "@/components/Avatar";

import { mapState, mapActions } from "vuex";

export default {
    name: "default-layout",
    components: {
        Avatar
    },
    data() {
        return {
            sidebar_visibility: {
                sm_hidden: true,
                md_hidden: false
            }
        };
    },
    created() {
        this.updateOutstandingCount().then(() => {
            this.startPollOutstandingCount();
        });
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.profilepopup).popup({
            position: "bottom right",
            on: "click",
            lastResort: "bottom right"
        });
    },
    beforeDestroy() {
        this.stopPollOutstandingCount();

        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        keepAliveViews() {
            //return (process.env.NODE_ENV === "production")? "" : /.+/;
            return (process.env.NODE_ENV === "production")? ["customers", "outstandings" ,"users", "outlets", "treatments", "referred-registrations", "devices"] : [];
        },
        displayRole() {
            return `${this._user.role.charAt(0).toUpperCase()}${this._user.role.toLowerCase().slice(1)}`;
        },
        ...mapState("outstanding", {
            outstanding_count: "count"
        })
    },
    methods: {
        popstateHandler(event) {
            this.hideProfilePopup();
            this.hideMenu();
        },
        toggleMenu() {
            if(this.is_lg) {
                this.sidebar_visibility.md_hidden = !this.sidebar_visibility.md_hidden;
            } else {
                this.sidebar_visibility.sm_hidden = !this.sidebar_visibility.sm_hidden;
            }
        },
        hideMenu() {
            if(!this.is_lg) {
                this.sidebar_visibility.sm_hidden = true;
            }
        },
        hideProfilePopup() {
            $(this.$refs.profilepopup).popup("hide");
        },
        logOut() {
            this.authLogout().then(() => {
                this.hideProfilePopup();

                this.$router.push({
                    name: "Login"
                });
            });
        },
        ...mapActions({
            authLogout: "auth/logout",
            startPollOutstandingCount: "outstanding/startPolling",
            stopPollOutstandingCount: "outstanding/stopPolling",
            updateOutstandingCount: "outstanding/fetchUpdate"
        })
    }
};
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    min-height: 100vh;
    align-items: stretch;
    color: #3c4b64;

    .sidebar {
        width: 256px;
        flex: 0 0 256px;
        background: #3c4b64;
        color: #fff;
        transition: 300ms;
        will-change: margin;

        @media only screen and (min-width: 992px) {
            &.md-hidden {
                margin-left: -256px;
            }
        }

        .logo {
            height: 56px;
            padding: 1em;
            text-align: center;
            background: #303c54;
            position: sticky;
            top: 0;
            z-index: 99;

            img {
                max-height: 100%;
                filter: invert(1);
            }
        }

        nav.ui.menu {
            margin-top: 0;
            position: sticky;
            top: 56px;
            z-index: 99;

            &.secondary.pointing {
                .item {
                    padding-top:  1em;
                    padding-bottom: 1em;
                }

                &.inverted {
                    .active.item {
                        background: hsla(0,0%,100%,.05);
                    }
                }
            }
        }
    }

    .ui.profile.popup {
        padding: 0;
        border: none;
        box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%), 0 -1px 0 0 rgb(34 36 38 / 1%);

        ::v-deep {
            .avatar {
                width: 100%;
                height: 100%;

                text {
                    fill: #000;
                }
            }
        }
    }

    .profile.item {
        .email {
            white-space: nowrap;
        }
    }

    .ui.link.list {
        a {
            display: block;
        }
    }

    main {
        flex: 1 1 0;
        min-width: 0;
        position: relative;
        background: #f7f8fa;

        header {
            height: 56px;
            border-bottom: 1px solid #eee;/*#d8dbe0;*/
            position: sticky;
            top: 0;
            z-index: 99;
            background: #fff;

            nav {
                display: flex;
                align-items: stretch;
                height: 100%;

                > * {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .menu-toggler {
                    background: none;
                    border: none;
                    min-width: 50px;
                    font-size: 1.25rem;
                    color: rgba(0,0,21,.5);
                    cursor: pointer;
                }

                .ui.text.menu {
                    margin: auto 1rem;
                }

                *[class*="right menu"] {
                    margin-right: 0.5rem;
                    margin-left: auto;
                }

                ::v-deep {
                    .avatar {
                        width: 36px;
                        height: 36px;
                        cursor: pointer;

                        label {
                            cursor: pointer;
                        }
                    }

                    + .ui.popup {
                        .ui.menu {
                            .item {
                                color: #4f5d73;
                            }
                        }
                    }
                }
            }
        }

        article.content {
            ::v-deep {
                > * {
                    padding: 1rem;
                }
            }
        }

        .backdrop {
            display: none;
            position: absolute;
            inset: 0;
            background: #000015;
            opacity: 0.5;
            z-index: 99;
        }
    }

    ::v-deep {
        .ui.table {
            color: #3c4b64;
        }
    }

    @media only screen and (max-width: 991px) {
        position: relative;

        .sidebar {
            position: absolute;
            top: 0;
            left: 0;
            min-height: 100%;
            z-index: 9;

            &.sm-hidden {
                margin-left: -256px;
            }
        }

        main {
            z-index: 8;

            .backdrop {
                display: block;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        main {
            header {
                padding: 0 1rem;
            }

            article.content {
                ::v-deep {
                    > * {
                        padding: 2rem;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        main {
            article.content {
                ::v-deep {
                    > * {
                        > .ui.ui.ui.ui.stackable.grid {
                            margin-left: -1rem !important;
                            margin-right: -1rem !important;
                        }
                    }
                }
            }
        }

        .ui.profile.popup {
            right: 0 !important;
            left: 0 !important;
            max-width: none;
            height: calc(100vh - 56px);
            background: #fff;
        }

        .profile.item {
            .email {
                word-break: break-all;
            }
        }
    }
}
</style>